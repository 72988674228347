import { HashRouter, Routes, Route } from "react-router-dom";
import SignUp from "./Pages/Login/SignUp";
import SignUpV2 from "./Pages/Login/SignUpV2";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import ContactUs from "./Pages/Contact/ContactUs";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { PrivacyPolicy } from "./components/Privacy/PrivacyPolicy";
import About from "./Pages/About/About";
import LoginV2 from './Pages/Login/LoginV2';
import Loader from "./components/Loader/Loader";

// Login
function App() {
  return (
    <div>
      {/* <Loader /> */}
      <HashRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}

          {/* <Route path="/login" element={<LoginV2 />} /> */}
          <Route path="/signup" element={<SignUpV2 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<About/>} />
        </Routes>
          <Footer /> 
      </HashRouter>
    </div>
  );
}

export default App;
