import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UserFeedback from '../../components/userFeedback/userFeedback';
import "./SignUpV2.css";
import axios from 'axios';
import Navbar from "../../components/Navbar/Navbar"
import Footer from "../../components/Footer/Footer"
import Loader from '../../components/Loader/Loader';
import { setLoading } from '../../RtkStore/slices/loaderSlice';
import { useDispatch } from 'react-redux';

import { useSelector } from "react-redux";
// import '../Loader/Loader.scss';
import '../../components/Loader/Loader.scss'

const SignUpV2 = () => {
    const loading = useSelector((state) => state.loader.loading);
    const dispatch = useDispatch()


    // const [loader, setLoader] = useState(false);
    // State for form values and error messages
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        accountName: '',
        accountType: ''
    });

    const [errors, setErrors] = useState({});

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Validate form fields
    const validateForm = () => {
        const newErrors = {};

        if (!formData.firstname.trim()) {
            newErrors.firstname = 'First Name is required';
        }
        if (!formData.lastname.trim()) {
            newErrors.lastname = 'Last Name is required';
        }
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.phoneNumber.trim()) {
            newErrors.phoneNumber = 'Phone Number is required';
        } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'Phone Number must be exactly 10 digits';
        }
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
        }
        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }
        if (!formData.confirmPassword) {
            newErrors.confirmPassword = 'Confirm Password is required';
        }
        if (!formData.accountName) {
            newErrors.accountName = 'Account Name is required';
        }
        if (!formData.accountType) {
            newErrors.account = 'Account Type is required';
        }


        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoader(true);
        dispatch(setLoading(true));
        try {
            if (validateForm()) {
                // If the form is valid, make an API call to sign up the user
                await axios.post("https://an2sldwgo0.execute-api.ap-south-1.amazonaws.com/stage1/public/user/signup", formData).then((res) => {
                    if (res.status === 200) {
                        console.log("Signup successful")
                        // setLoader(false);
                        dispatch(setLoading(false));
                        window.location = "http://saas.katalisia.com/";
                    }
                });
                console.log('formData: ' + formData);
            }
        } catch (error) {
            // setLoader(false);
            dispatch(setLoading(false));
            console.log(error.response.data);
            alert(error.response.data);
            return error.response;
        }
        finally {
            dispatch(setLoading(false)); // Hide loader
        }
        // Simulate a fetch call here
        console.log('Form submitted successfully:', formData);
        // Reset form fields after successful submission
        setFormData({
            firstname: '',
            lastname: '',
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            accountName: '',
            accountType: ''
        });
    }

    return (
        <>
            <Navbar />

            {/* <div className={`${loading ? "loader-active" : ""}`} style={{ position: "relative" }}>
                <div className="loader-bg">
                    {loading &&
                        <div className="loader-inner-container">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    }
                </div>
            </div> */}

            {loading && <Loader />}



            <div className='signUp-wrapper'>
                <div className='form-container'>
                    <div className='formleft-side'>
                        <UserFeedback para1={"“Welcome to Katalisia! We're excited to have you on board. To get started, please fill out the form below with your name, email address, and a secure password. Once you've completed the form, you'll receive a confirmation email with instructions on how to access your account.”"} para2={"Thank you for joining Katalisia - we can't wait to see what you'll achieve!."} />
                    </div>

                    <div className='formright-side'>
                        <h2>Create Account</h2>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="input-container">
                                <div className="form__group field">
                                    <input
                                        type="text"
                                        className="form__field"
                                        placeholder="First Name"
                                        name="firstname"
                                        id="firstname"
                                        value={formData.firstname}
                                        onChange={handleInputChange}
                                        aria-describedby="firstname-error"
                                        required
                                    />
                                    <label htmlFor="firstname" className="form__label">First Name</label>
                                    {errors.firstname && <span id="firstname-error" className="error-message">{errors.firstname}</span>}
                                </div>

                                <div className="form__group field">
                                    <input
                                        type="text"
                                        className="form__field"
                                        placeholder="Last Name"
                                        name="lastname"
                                        id="lastname"
                                        value={formData.lastname}
                                        onChange={handleInputChange}
                                        aria-describedby="lastname-error"
                                        required
                                    />
                                    <label htmlFor="lastname" className="form__label">Last Name</label>
                                    {errors.lastname && <span id="lastname-error" className="error-message">{errors.lastname}</span>}
                                </div>
                            </div>

                            <div className="input-container">
                                <div className="form__group field">
                                    <input
                                        type="email"
                                        className="form__field"
                                        placeholder="Email"
                                        name="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        aria-describedby="email-error"
                                        required
                                    />
                                    <label htmlFor="email" className="form__label">Email</label>
                                    {errors.email && <span id="email-error" className="error-message">{errors.email}</span>}
                                </div>

                                <div className="form__group field">
                                    <input
                                        type="tel"
                                        className="form__field"
                                        placeholder="Phone Number"
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                        onInput={(e) => {
                                            // Remove any non-numeric characters
                                            e.target.value = e.target.value.replace(/\D/g, '');
                                        }}
                                        aria-describedby="phoneNumber-error"
                                        pattern="\d*"
                                        maxLength="10"
                                        required
                                    />
                                    <label htmlFor="phoneNumber" className="form__label">Phone Number</label>
                                    {errors.phoneNumber && <span id="phoneNumber-error" className="error-message">{errors.phoneNumber}</span>}
                                </div>
                            </div>

                            <div className="input-container">
                                <div className="form__group field">
                                    <input
                                        type="password"
                                        className="form__field"
                                        placeholder="Password"
                                        name="password"
                                        id="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        aria-describedby="password-error"
                                        required
                                    />
                                    <label htmlFor="password" className="form__label">Password</label>
                                    {errors.password && <span id="password-error" className="error-message">{errors.password}</span>}
                                </div>

                                <div className="form__group field">
                                    <input
                                        type="password"
                                        className="form__field"
                                        placeholder="Confirm Password"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleInputChange}
                                        aria-describedby="confirmPassword-error"
                                        required
                                    />
                                    <label htmlFor="confirmPassword" className="form__label">Confirm Password</label>
                                    {errors.confirmPassword && <span id="confirmPassword-error" className="error-message">{errors.confirmPassword}</span>}
                                </div>

                            </div>


                            <div className="input-container">

                                <div className="form__group field">
                                    <input
                                        type="text"
                                        className="form__field"
                                        placeholder="AccountName"
                                        name="accountName"
                                        id="accountName"
                                        value={formData.accountName}
                                        onChange={handleInputChange}
                                        aria-describedby="accountName-error"
                                        required
                                    />
                                    <label htmlFor="accountName" className="form__label">Account</label>
                                    {errors.accountName && <span id="accountName-error" className="error-message">{errors.accountName}</span>}
                                </div>

                                <div className="form__group field">
                                    <select
                                        class="form-select"
                                        aria-label="Default select example"
                                        name="accountType"
                                        onChange={handleInputChange}
                                    >
                                        <option selected>Select Account type</option>
                                        <option value="INFLUENCER">INFLUENCER</option>
                                        <option value="PARTNER">PARTNER</option>
                                        <option value="COMPANY">COMPANY</option>
                                    </select>
                                    {/* <label htmlFor="password" className="form__label">Password</label> */}
                                    {errors.accountType && <span id="accountType-error" className="error-message">{errors.accountType}</span>}
                                </div>

                            </div>

                            <button type="submit" className="submit-button">Sign Up</button>
                        </form>

                        <p className='sign-in-btn'>
                            Already have an account? <br />
                            <Link to="/login">Sign In</Link>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default SignUpV2;
