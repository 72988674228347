import React from "react";
import Logo from "../Logo/Logo";
import facebook from "../../assets/svgs/facebook.svg";
import twitter from "../../assets/svgs/twitter.svg";
import linkedin from "../../assets/svgs/linkedin.svg";
import youtube from "../../assets/svgs/youtube.svg";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  if (
    location.pathname === "/signup" ||
    location.pathname === "/privacy-policy"
  ) {
    return null;
  }

  return (
    <div className="footer-container">
      <div className="footer-logo">
        <Logo />
      </div>
      <div className="footer-links-section">
         <Link to={"/about"} className="footer-links" >
          About Us
        </Link> <Link to={"/contact"} className="footer-links" >
          Contact Us
        </Link>
        <Link to={"/privacy-policy"} className="footer-links">
          Privacy Policy
        </Link>
      </div>

      <div className="footer-lower-section">
        <img src={facebook} alt="facebook" />
        <img src={linkedin} alt="linkedin" />
        <img className="twitter-icon" src={twitter} alt="twitter" />
        <img src={youtube} alt="youtube" />
      </div>
    </div>
  );
};

export default Footer;
