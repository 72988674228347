import logo from "../../assets/images/logo.png";

const Logo = () => {
  return (
    <div className="logo">   
      <img className="logo-image" src={logo} alt="logo" />
      <div className="logo-title">Katalisia</div>
    </div>
  );
};

export default Logo;
