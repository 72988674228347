import React, { useState, useEffect } from 'react';
import './StatisticsGrid.scss';

const sliderData = [
    ["98%", "Daily Active Social Media Users: 3.78 Billion. Which is nearly 50% of the world population"],
    ["73%", "of the world markets believe in social media marketing.", "74%", "of the users, rely on Social Media for purchases."],
    ["42%", "of the influencers who saw influencer ads, Bought It"],
    ["8%", "The global social media advertising market was valued at $93.5 billion in 2019 and is expected to reach $176.2 billion by 2028, growing at a CAGR of 7.6%."],
    ["89%", "of marketers find ROI from influencer marketing comparable to or better than other marketing channels. (According to a survey conducted by Influencer Marketing Hub)"],
    ["98%", "In India, there are around 80 million content creators, but only 1.5 lakh can monetize their services effectively. 0.2 percent. (Source: YourStory)"],
    ["75%", "of marketers consider influencer marketing effective for generating engagement. (According to a survey by Adweek)"],
    ["63%", "Consumers trust influencer messages more than brand messages. (Source: Edelman)"],
    ["30%", "of consumers are more likely to buy a product recommended by a non-celebrity blogger."],
    ["98%", "Businesses make an average of $6.50 for every $1 invested in influencer marketing. (In a survey conducted by Tomoson)"]
];

const Coverflow = () => {
    const [current, setCurrent] = useState(0);

    const nextSlide = () => {
        setCurrent((prev) => (prev === sliderData.length - 1 ? 0 : prev + 1));
    };

    const prevSlide = () => {
        setCurrent((prev) => (prev === 0 ? sliderData.length - 1 : prev - 1));
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 3000); 

        return () => clearInterval(interval); 
    }, []);

    return (
        <div className="coverflow-container">
            <button className="nav-button left" onClick={prevSlide}>‹</button>
            <div className="coverflow-wrapper">
                {sliderData.map((item, index) => {
                    let position = 'nextSlide';
                    if (index === current) {
                        position = 'activeSlide';
                    } else if (
                        index === current - 1 ||
                        (current === 0 && index === sliderData.length - 1)
                    ) {
                        position = 'lastSlide';
                    }

                    return (
                        <div className={`coverflow-card ${position}`} key={index}>
                            <h2>{item[0]}</h2>
                            {item.slice(1).map((detail, i) => (
                                <p key={i}>{detail}</p>
                            ))}
                        </div>
                    );
                })}
            </div>
            <button className="nav-button right" onClick={nextSlide}>›</button>
        </div>
    );
};

export default Coverflow;