import { useEffect } from "react";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="privacy-policy">
      <h2>Privacy Policy for Katalisia </h2>
      <br />
      <div>
        At Katalisia, we are committed to protecting your privacy. This Privacy
        Policy explains how we collect, use, and disclose your personal
        information when you use our software.{" "}
      </div>
      <br />
      <h4>Information We Collect </h4>
      <br />
      When you use our software, we may collect the following information:
      <ol>
        <li>
          Personal information you provide to us, such as your name, email
          address, and social media account information.{" "}
        </li>
        <li>
          Information about your use of our software, including information
          about the content you create and publish on social media platforms.
        </li>
        <li>
          Information about your device, such as your device type, operating
          system, and IP address.{" "}
        </li>
      </ol>
      <br />
      <h4>How We Use Your Information </h4>
      <br />
      We use your personal information for the following purposes:
      <ol>
        <li>To provide and improve our software and services. </li>

        <li>To analyze and report on social media performance. </li>

        <li>
          To match content creators with companies for sponsorship and
          advertising opportunities.{" "}
        </li>

        <li>To communicate with you about our software and services. </li>

        <li>To comply with legal obligations. </li>
      </ol>
      <br />
      <h4>Disclosure of Your Information</h4>
      <br />
      We may disclose your personal information to the following third parties:
      <ol>
        <li>
          Companies that match with content creators for sponsorship and
          advertising opportunities.{" "}
        </li>
        <li>
          Service providers that help us provide and improve our software and
          services.{" "}
        </li>
        <li>Regulatory authorities, if required by law. </li>
      </ol>
      <br />
      <h4>Data Security</h4>
      <br />
      <p>
        We take appropriate technical and organizational measures to protect
        your personal information against unauthorized access, use, and
        disclosure. However, please note that no transmission of data over the
        internet is completely secure, and we cannot guarantee the security of
        your information.{" "}
      </p>
      <br />
      Your Rights You have the following rights with respect to your personal
      information:
      <br />
      <ol>
        <li>
          The right to access and receive a copy of your personal information.{" "}
        </li>

        <li>
          The right to request that we correct, update, or delete your personal
          information.{" "}
        </li>
        <li>
          The right to object to the processing of your personal information.{" "}
        </li>
        <li>
          The right to withdraw your consent to the processing of your personal
          information.{" "}
        </li>
      </ol>
      <br />
      To exercise your rights, please contact us using the contact information
      provided below.
      <br />
      <br />
      <h4>Changes to This Privacy Policy </h4>
      <br />
      <p>
        We may update this Privacy Policy from time to time to reflect changes
        to our information practices. If we make any material changes, we will
        notify you by email or by posting a notice on our website.{" "}
      </p>
      <br />
      <h4>Contact Us </h4>
      <br />
      If you have any questions or concerns about our Privacy Policy, please
      contact us at rohan@katalisia.com <br />
      <br />
      <br />
      <span>
        <Link to={"/"}>
          <button className="privacy-button">Ok</button>
        </Link>
      </span>
    </div>
  );
};
