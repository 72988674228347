import React, { useState } from 'react';
import './Faq.scss'

const Faq = () => {

    const questions = [
        {
            id: 1,
            question: 'Is Katalisia suitable for content creators of all sizes?',
            answer: 'Absolutely! Katalisia is designed to support content creators of all sizes—from rising influencers to well-established creators. Our platform provides valuable insights and tools to help you grow your audience and maximize your revenue, regardless of where you are in your content creation journey.',
        },
        {
            id: 2,
            question: 'How does Katalisia help companies with ad campaigns?',
            answer: 'Katalisia allows companies to create targeted ad campaigns that reach the right audience. By connecting with influencers who match your brand’s needs, our platform ensures that your message reaches the most relevant viewers, maximizing your return on investment.',
        },
        {
            id: 3,
            question: 'What makes Katalisia different from other influencer marketing platforms?',
            answer: 'Katalisia stands out by combining advanced analytics with a seamless ad integration process. Our platform not only helps you connect with the right influencers but also provides data-driven insights and recommendations to optimize your content and campaigns for maximum impact.',
        },
        {
            id: 4,
            question: 'How can I get in touch with the Katalisia support team?',
            answer: 'We’re here to help! You can contact our support team through the "Contact Us" page on our website or reach out directly via email at support@katalisia.com. We strive to respond to all inquiries within 24 hours.',
        },

    ]

    return (
        <div className='faq-container'>
            <h2 className="heading">How can we help you?</h2>
            <section className='faq'>
                {questions.map(item => <Question id={item.id} question={item.question} answer={item.answer} />)}
            </section>
        </div>
    )
}

export default Faq

const Question = ({ id, question, answer }) => {
    const [isActive, setActive] = useState(false);
    const handleClick = (id) => {
        setActive(!isActive)
    }
    return (
        <div className="question-wrapper">

            <div className='question' id={id}>
                <h3>{question}</h3>
                <button onClick={() => handleClick(id)}>
                    <svg className={isActive ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
                        <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
                    </svg>
                </button>
            </div>
            <div className={isActive ? 'answer active' : 'answer'}>{answer}</div>
        </div>
    )
}
