import React from 'react'
import { useSelector } from "react-redux";
import '../Loader/Loader.scss';

const Loader = () => {
    const loading = useSelector((state) => state.loader.loading);

    return (
        <div className={`${loading ? "loader-active" : ""}`} style={{ position: "relative" }}>
            <div className="loader-bg">
                {loading &&
                    <div className="loader-inner-container">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Loader
