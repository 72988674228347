import React from 'react'
import Slider from "react-slick";
import "./testimonials.scss";

const TestimonialV2 = () => {

    const testimonialData = [
        {
            id: 1,
            writerName: `Jane`,
            writerProfile: `Marketing Manager`,
            experience: `Katalisia has completely transformed the way I analyze my YouTube
            channel's performance. The advanced analytics and insights have helped
            me make better decisions in terms of content creation and engagement
            with my audience.`
        },
        {
            id: 2,
            writerName: `Tom`,
            writerProfile: `CEO, AGENCY`,
            experience: `I was struggling to find the right keywords and tags for my videos,
            which affected my visibility on YouTube. But since I started using
            Katalisia, I have seen a significant increase in my video views and
            overall engagement. Thank you, Katalisia!".`
        },
        {
            id: 3,
            writerName: `Sarah`,
            writerProfile: `Digital Marketing Manager`,
            experience: `Katalisia's engagement rate calculation has given me a much more
            accurate picture of how my audience is responding to my content. The
            metrics provided have allowed me to adjust my strategy and create
            content that resonates with my viewers."`
        },
        {
            id: 4,
            writerName: `James`,
            writerProfile: `Digital Agency Owner`,
            experience: `Katalisia has provided us with invaluable insights into our YouTube
            marketing strategy. The detailed analytics and reporting have helped
            us identify areas of improvement and maximize our return on
            investment."`
        },
        {
            id: 5,
            writerName: `Emily`,
            writerProfile: `Digital Marketing Consultant`,
            experience: `Our company has been using Katalisia to analyze our competitors'
            YouTube channels, and the results have been remarkable. The data
            provided has allowed us to stay ahead of the game and make data-driven
            decisions in our marketing efforts."`
        },
        {
            id: 6,
            writerName: `Mark`,
            writerProfile: `Social Media Strategist`,
            experience: `Katalisia's advanced analytics have helped us gain a deeper
          understanding of our target audience and their preferences. This has
          allowed us to create more personalized and engaging content that
          resonates with our customers."`
        },
        {
            id: 7,
            writerName: `John`,
            writerProfile: `YouTuber`,
            experience: `Katalisia has been a game-changer for our agency. The advanced
          analytics and insights have helped us deliver better results for our
          clients and stand out in a competitive market."`
        },
        {
            id: 8,
            writerName: `Sahil`,
            writerProfile: `Content Creator`,
            experience: `The detailed reporting and analysis provided by Katalisia have
          allowed us to optimize our clients' YouTube campaigns and increase
          their engagement rates significantly. The platform is user-friendly
          and offers a wide range of features that cater to all our needs."`
        },
        {
            id: 9,
            writerName: `Mike`,
            writerProfile: `Vlogger`,
            experience: `Katalisia's engagement rate calculation has been a valuable addition
          to our agency's toolkit. The data provided has allowed us to make
          informed decisions for our clients' YouTube channels and improve their
          overall performance."`
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 5500,
        arrows: false,
    };

    return (
        <>
            <div className='testimonial-container'>
                <h2 className="heading">testimonials</h2>
                <Slider {...settings}>
                    {
                        testimonialData.map((data) => (
                            <div className="testimonial-rightsideParent">
                                <div className="right-side" key={data.id}>
                                    <img className="testimonial-writerImage" src="https://cdn-icons-png.flaticon.com/512/147/147142.png" />
                                    <h2 className="testimonial-writerName">{data.writerName}</h2>
                                    <p className="testimonial-writerProfile">{data.writerProfile}</p>
                                    <p className="testimonial-writerExperience"><span>"</span>{data.experience}<span>"</span></p>
                                    <div className="testimonial-starContainer">
                                        <img className="testimonial-star" src="https://thumbs.dreamstime.com/b/gold-star-shape-isolated-black-background-golden-icon-logo-image-symbol-graphic-element-decorate-embellish-design-144735098.jpg" />
                                        <img className="testimonial-star" src="https://thumbs.dreamstime.com/b/gold-star-shape-isolated-black-background-golden-icon-logo-image-symbol-graphic-element-decorate-embellish-design-144735098.jpg" />
                                        <img className="testimonial-star" src="https://thumbs.dreamstime.com/b/gold-star-shape-isolated-black-background-golden-icon-logo-image-symbol-graphic-element-decorate-embellish-design-144735098.jpg" />
                                        <img className="testimonial-star" src="https://thumbs.dreamstime.com/b/gold-star-shape-isolated-black-background-golden-icon-logo-image-symbol-graphic-element-decorate-embellish-design-144735098.jpg" />
                                        <img className="testimonial-star" src="https://thumbs.dreamstime.com/b/gold-star-shape-isolated-black-background-golden-icon-logo-image-symbol-graphic-element-decorate-embellish-design-144735098.jpg" />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </>
    )
}

export default TestimonialV2
