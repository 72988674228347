import React from "react";
import "./about.scss";
import { Button } from "@mui/material";
import {Link} from 'react-router-dom'
import { useEffect } from "react";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main style={{backgroundColor:"#ebecf0"}}>
      <div className="about-container">
        <div className="about-header">
          <h1>About Us</h1>
        </div>
        <div className="about-content">
          <p>
            At Katalisia, we're passionate about helping content creators,
            companies, and social media marketing agencies unlock the full
            potential of social media. We believe that with the right tools and
            insights, anyone can succeed on platforms like YouTube, Instagram,
            Facebook and beyond.
          </p>
          <p>
            Our team of experienced developers and data analysts has worked
            tirelessly to create a software solution that provides deep insights
            into social media engagement and monetization. Our platform is
            designed to be easy to use yet powerful, allowing users to quickly
            analyze their data and make informed decisions.
          </p>
          <p>
            But we're not just about technology. We're also committed to
            providing top-notch support and guidance to our users. Whether
            you're just starting out or have been in the social media game for a
            while, we're here to help you achieve your goals.
          </p>
          <p>
            So if you're looking to take your social media presence to the next
            level, give Katalisia a try. We're confident that our platform will
            help you unlock new opportunities and reach new heights of success.{" "}
          </p>

          <center>
            <Button variant="contained" className="button">
             <Link to='/signup' className="signup_btn"> Sign-Up</Link>
            </Button>
          </center>
        </div>
      </div>
      <div className="container">
        <h2 className="title">Our Vision</h2>
        <div className="content">
          <p>
            Our vision is to become the go-to platform for social media
            analytics and engagement tracking.
          </p>
          <p>
            By leveraging the power of data and insights, we hope to help
            content creators and companies around the world to increase their
            visibility, reach new audiences, and drive growth.{" "}
          </p>
          <p>
            At Katalisia, we believe that our success is measured by the success
            of our users. We are committed to providing a platform that is easy
            to use, accurate, and delivers value to our customers.{" "}
          </p>
          <p>
            Join us on our mission to empower content creators and businesses to
            reach their full potential through social media.{" "}
          </p>
        </div>
      </div>
    </main>
  );
}

export default About;
