import React, { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "../Features/Features.scss";

const featureData = [
  {
    title: "Creator Suite",
    description: "Empower your content creation with Katalisia’s Creator Suite. Gain access to advanced analytics, personalized recommendations, and tools that help you optimize your content strategy. Whether you're a rising influencer or an established creator, our suite enables you to understand your audience better and maximize your reach and revenue.",
    callToAction: "Learn More"
  },
  {
    title: "Ad Campaigns",
    description: "Maximize your ad spend with Katalisia’s targeted Ad Campaign solutions. Connect with the right influencers and place your brand directly in front of your desired audience. Our platform offers precise targeting, performance analytics, and seamless integration to ensure your campaigns drive results and resonate with viewers.",
    callToAction: "Explore Ad Campaigns"
  },
  {
    title: "Agencies",
    description: "Streamline your workflow and amplify your clients' social media presence with Katalisia’s suite of tools designed for digital agencies. From campaign management to detailed reporting and collaboration tools, we provide everything you need to efficiently manage multiple clients and deliver measurable results.",
    callToAction: "Discover Agency Solutions"
  }
];

const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
};

const Features = () => {
    const [expanded, setExpanded] = useState(Array(featureData.length).fill(false));

    const handleExpandClick = (index) => {
        const newExpandedState = [...expanded];
        newExpandedState[index] = !newExpandedState[index];
        setExpanded(newExpandedState);
    };

    return (
        <div className="features-parent">
            <h3>Features</h3>
            <p>From the Creator Suite for optimized content creation to Ad Campaigns that connect you with the right influencers, and Agency Solutions to streamline workflows—Katalisia offers the tools and insights to drive growth, engagement, and success across all digital platforms.</p>
            <div className="feature-main-container">
                {/* Dynamic Feature Cards */}
                <div className="feature-cardWrapper">
                    {featureData.map((feature, index) => (
                        <div className="feature-card" key={index}>
                            <div className='feature-cardContent'>
                                <div className="feature-top">{feature.title}</div>
                                <div className="feature-content">
                                    {expanded[index] ? feature.description : truncateText(feature.description, 18)}
                                </div>
                                <button className="cta-button" onClick={() => handleExpandClick(index)}>
                                    {expanded[index] ? "← Show Less" : feature.callToAction}
                                    <ArrowForwardIcon />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Feature Image is here */}
                <div className="feature-image-wrapper">
                    <img src="https://images.pexels.com/photos/1170412/pexels-photo-1170412.jpeg?cs=srgb&dl=pexels-cadomaestro-1170412.jpg&fm=jpg" alt="Feature Image" />
                </div>
            </div>
        </div>
    );
}

export default Features;
