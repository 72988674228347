import React from "react";
import "./contact.scss";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Facebook, Twitter, Instagram } from "@material-ui/icons";
import { useState } from "react";
import axios from "axios";
import { Avatar, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  iconButton: {
    width: "100px",
    margin: "20px 10px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0",
    },
  },
}));

function ContactUs() {
  const classes = useStyles();
  const [input, setInputs] = useState({ name: "", email: "", message: "" });
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInput = (e) => {
    setInputs({ ...input, [e.target.name]: e.target.value });
  };

  const postContactData = async () => {
    const { name, email, message } = input;
    await axios
      .post(
        "https://an2sldwgo0.execute-api.ap-south-1.amazonaws.com/stage1/public/user/contact",
        { name, email, message }
      )
      .then((res) => {
        console.log("resstatus", res.status);
        if (res.status === 200) {
          setOpen(true);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = input;
    let isFormValid = true;
    let errorMessage = "";

    if (!name || !email || !message) {
      isFormValid = false;
      errorMessage = "Please fill all the required fields";
    }

    // eslint-disable-next-line
    if (email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      isFormValid = false;
      errorMessage = "Please enter a valid email address";
    }

    if (!isFormValid) {
      alert(errorMessage);
      return;
    }

    postContactData();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="header_section">
        We're here to answer any questions you may have about Katalisia. Feel
        free to reach out to us and we'll respond as soon as possible. You can
        contact us through the following methods: Email: info@katalisia.com (To
        be taken, remove after taken) Phone: +1 (555) 555-5555 (To be taken,
        remove after taken) Social Media: Follow us on Linkedin, Twitter and
        Instagram for updates and news about Katalisia. (Logos for social media
        sites and reditects) Or, fill out the form below and we'll get back to
        you as soon as we can.
      </div>
      <div className="container">
        <div className="form-container">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                className="input"
                id="name"
                name="name"
                placeholder="Enter your name"
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                className="input"
                placeholder="Enter your email"
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                className="textarea"
                name="message"
                placeholder="Enter your message"
                onChange={handleInput}
              ></textarea>
            </div>
            <button className="buttonSubmit" type="submit">
              Send Message
            </button>
          </form>

          <div className="info-container">
            <div className={classes.root}>
              <IconButton className={classes.iconButton} aria-label="Facebook">
                <Facebook className={classes.icons} />
              </IconButton>
              <IconButton className={classes.iconButton} aria-label="Twitter">
                <Twitter />
              </IconButton>
              <IconButton className={classes.iconButton} aria-label="Instagram">
                <Instagram />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <div className="header_section">
        We value your feedback and suggestions, so please don't hesitate to let
        us know how we can improve our services to better serve you.
      </div>

      {open ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Avatar
              alt="Remy Sharp"
              src="https://cdn-icons-png.flaticon.com/512/190/190411.png"
            />
            <Typography
              sx={{ ml: 2 }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Thank you for your message, we will get back to you soon.
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </>
  );
}

export default ContactUs;
