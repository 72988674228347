import { Link } from "react-router-dom";
// import localImage from "../../assets/images/localImage.png";
import "../Hero_section/Hero.scss"

const heroContent = [
  "Unlock the full potential of your content with Katalisia’s advanced analytics and seamless ad integration."
];
const Hero = () => {
  return (
    <>
      <div className="homeContainer">
        <div className="centerContainer">
          <div className="leftContent">
            <h1 className="text-whit">
              Transform Your <span> Content into Revenue.</span>
            </h1>
           
            <h4>
              <ul>
                {heroContent.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </h4>

            <div className="Hero-btnWrapper">

              <Link to={"/signup"}>
                <button type="button" class="heroBtn1">
                  Explore Our Platform
                </button>
              </Link>

              <Link to={"/signup"}>
                <button type="button" class="heroBtn2">
                  Start Your Free Trial
                </button>
              </Link>

            </div>

          </div>
          {/* <div className="rightContent">
            <img src={localImage} alt="marketing" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Hero;
