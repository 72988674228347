import Logo from "../Logo/Logo";

const UserFeedback = ({ para1, para2 }) => {


  return (
    <div className="userFeedback-main-container">
      <div style={{ textAlign: "left" }}>
        <Logo />
      </div>

      <br />
      <div>
        {para1}

        <br /><br />
        {para2}
      </div>


    </div>
  );
};

export default UserFeedback;
